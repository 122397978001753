// SETUP COLORS
export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const PRIMARY = {
  light: '#fd3333',
  main: '#FD0100',
  dark: '#b10000',
};

export const SECONDARY = {
  light: '#d1d5db',
  main: '#6b7280',
  dark: '#374151',
};

export const INFO = {
  light: '#03a9f4',
  main: '#0288d1',
  dark: '#01579b',
};

export const SUCCESS = {
  light: '#4caf50',
  main: '#2e7d32',
  dark: '#1b5e20',
};

export const WARNING = {
  light: '#ff9800',
  main: '#ed6c02',
  dark: '#e65100',
};

export const ERROR = {
  light: '#ef5350',
  main: '#d32f2f',
  dark: '#c62828',
};
