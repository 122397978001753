import CELL_DATA_TYPES from '../cell-types.config';

// Playlists module configurations
export const LISTING_COLUMNS = [
  {
    id: 'playlistId',
    label: 'ID',
    width: '20%',
    align: 'left',
    dataKey: 'playlistId',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'title',
    label: 'Title',
    width: '50%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'totalSessions',
    label: 'Total Watch Sessions',
    width: '15%',
    align: 'right',
    dataKey: 'totalSessions',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalSecondsWatch',
    label: 'Total Watch Time',
    width: '15%',
    align: 'right',
    dataKey: 'totalSecondsWatch',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
];

export const LISTING_ACTIONS = [];
