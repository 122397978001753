import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Channels from '../form-elements/channels.component';
import {
  viewDataById,
  assignChannelsById,
} from '../../services/smtp-settings.service';
import StyledDialogActions from '../../theme/dialogActionStyles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AssignChannels = ({ title, dataId, onCancel, onSuccess }) => {
  const [formData, setFormData] = useState({
    channels: [],
  });
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleChannelChange = (v) => {
    setFormData({
      ...formData,
      channels: v,
    });
  };

  const handleSaveData = () => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      channels: [],
    };
    (formData.channels || []).forEach((c) => {
      payload.channels.push(Number(c.id));
    });
    assignChannelsById(dataId, payload)
      .then(() => {
        onSuccess('Settings saved successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    viewDataById(dataId)
      .then((res) => {
        const channels = [];
        (res.data?.assignedChannels || []).forEach((c) => {
          channels.push({
            id: c.id || '',
            code: c.code || '',
            title: c.title || '',
          });
        });
        setFormData({
          ...formData,
          channels,
        });

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="assign-channels-dialog-title"
      aria-describedby="assign-channels-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="assign-channels-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="assign-channels-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <Grid container spacing={2} sx={{ marginBottom: 10 }}>
            <Grid item xs={12} sm={6} md={6}>
              <>
                <Channels
                  id="dr_channel"
                  name="dr_channel"
                  label="Select Channel"
                  defaultValue={formData.channels}
                  multiple
                  onChange={handleChannelChange}
                  sx={{ width: '100%', my: 1 }}
                />
                <Typography variant="body2" color="secondary">
                  Select channels where this email setting needs to be used for
                  sending an email.
                </Typography>
              </>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <StyledDialogActions>
        <div>
          <Button
            type="button"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
            onClick={handleSaveData}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </div>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AssignChannels.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AssignChannels;
