import CELL_DATA_TYPES from '../cell-types.config';

// Realtime live users module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'App Name',
    width: '12%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'TOTAL_COUNT',
    label: 'Total',
    width: '8%',
    align: 'right',
    dataKey: 'TOTAL_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'ROKU_COUNT',
    label: 'Roku',
    width: '10%',
    align: 'right',
    dataKey: 'ROKU_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'FIRETV_COUNT',
    label: 'Fire TV',
    width: '10%',
    align: 'right',
    dataKey: 'FIRETV_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'FIRE_MOBILE_COUNT',
    label: 'Fire Mobile',
    width: '10%',
    align: 'right',
    dataKey: 'FIRE_MOBILE_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'ANDROIDTV_COUNT',
    label: 'Android TV',
    width: '10%',
    align: 'right',
    dataKey: 'ANDROIDTV_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'ANDROID_MOBILE_COUNT',
    label: 'Android Mobile',
    width: '10%',
    align: 'right',
    dataKey: 'ANDROID_MOBILE_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'APPLETV_COUNT',
    label: 'Apple TV',
    width: '10%',
    align: 'right',
    dataKey: 'APPLETV_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'APPLE_MOBILE_COUNT',
    label: 'Apple Mobile',
    width: '10%',
    align: 'right',
    dataKey: 'APPLE_MOBILE_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'WEB_COUNT',
    label: 'Web',
    width: '10%',
    align: 'right',
    dataKey: 'WEB_COUNT',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
];

export const LISTING_ACTIONS = [];
