import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { STATUS_LABELS, STATUS_ALL_VALUE } from '../../config/const.config';

const Status = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  validOptions,
  disabled,
  sx,
  error,
  allOption,
}) => (
  <FormControl fullWidth error={error !== ''} variant="filled">
    <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id={id}
      name={name}
      value={defaultValue}
      label={label}
      onChange={onChange}
      disabled={disabled}
      sx={sx}
    >
      {allOption && <MenuItem value={STATUS_ALL_VALUE}>All</MenuItem>}
      {validOptions.map((k) => (
        <MenuItem key={`${id}-v-${k}`} value={k}>
          {STATUS_LABELS[k]}
        </MenuItem>
      ))}
    </Select>
    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

Status.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  allOption: PropTypes.bool,
};
Status.defaultProps = {
  disabled: false,
  sx: {},
  error: '',
  allOption: false,
};

export default Status;
