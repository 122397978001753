import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Payments module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '10%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'username',
    label: 'Username',
    width: '10%',
    align: 'left',
    dataKey: 'username',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '12%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channel',
    label: 'Channel',
    width: '10%',
    align: 'left',
    dataKey: 'channel',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Payment',
    width: '8%',
    align: 'center',
    dataKey: 'type',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'subscriptionType',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'sku',
    label: 'Subscription',
    width: '10%',
    align: 'left',
    dataKey: 'sku',
    sortable: true,
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '10%',
    align: 'center',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'amount',
    label: 'Amount',
    width: '8%',
    align: 'right',
    dataKey: 'amount',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'success',
    label: 'Success',
    width: '7%',
    align: 'center',
    dataKey: 'success',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'createdAt',
    label: 'Date',
    width: '10%',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Subscriber',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'username',
        type: CELL_DATA_TYPES.TEXT,
      },
      {
        dataKey: 'email',
        type: CELL_DATA_TYPES.EMAIL,
      },
    ],
  },
  {
    id: 'channel',
    label: 'Channel',
    dataKey: 'channel',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Payment',
    dataKey: 'type',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'subscriptionType',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'sku',
    label: 'Subscription',
    width: '10%',
    align: 'left',
    dataKey: 'sku',
    sortable: true,
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
  {
    id: 'mode',
    label: 'Mode',
    dataKey: 'mode',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'amount',
    label: 'Amount',
    dataKey: 'amount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'success',
    label: 'Success',
    dataKey: 'success',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'createdAt',
    label: 'Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'invoiceId',
    label: 'Invoice ID',
    dataKey: 'invoiceId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'chargeId',
    label: 'Charge ID',
    dataKey: 'chargeId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'payloadToCheck',
    label: 'Payload For Checking',
    dataKey: 'payloadToCheck',
    type: CELL_DATA_TYPES.CODE,
  },
  {
    id: 'paymentOtherInfo',
    label: 'Other Details',
    dataKey: 'paymentOtherInfo',
    type: CELL_DATA_TYPES.CODE,
  },
];
