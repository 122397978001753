import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// SMTP Settings module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '20%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'senderName',
    label: 'Sender Name',
    width: '20%',
    align: 'left',
    dataKey: 'senderName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'senderEmail',
    label: 'Sender Email',
    width: '20%',
    align: 'left',
    dataKey: 'senderEmail',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created',
    width: '15%',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'channels',
    label: 'Channels',
    width: '10%',
    align: 'right',
    dataKey: 'channels',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.ASSIGN_CHANNELS.value,
    label: ACTIONS.ASSIGN_CHANNELS.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'host',
    label: 'Host',
    dataKey: 'host',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'port',
    label: 'Port',
    dataKey: 'port',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'encryption',
    label: 'Encryption',
    dataKey: 'encryption',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'username',
    label: 'Username',
    dataKey: 'username',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'password',
    label: 'Password',
    dataKey: 'password',
    type: CELL_DATA_TYPES.PASSWORD,
  },
  {
    id: 'senderName',
    label: 'Sender Name',
    dataKey: 'senderName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'senderEmail',
    label: 'Sender Email',
    dataKey: 'senderEmail',
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'createdAt',
    label: 'Created',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'channels',
    label: 'Used In Channels',
    dataKey: 'channels',
    type: CELL_DATA_TYPES.NUMBER,
    secondaryFields: [
      {
        dataKey: 'channelNames',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
];
