import axios from 'axios';
import { getLocalValue } from '../utils/local-cache.util';
import { LS_ADMIN_USER_AUTH_TOKEN } from '../config/const.config';

const prepareRequestOptions = (url, method, data = null) => {
  const baseAPIUrl = process.env.REACT_APP_ANALYTICS_API_ENDPOINT;
  const credentials = process.env.REACT_APP_ANALYTICS_API_CREDENTIALS;
  const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Reports-Data': credentials,
      'X-Admin-Auth-Token': adminAuth,
    },
    data: JSON.stringify(data || {}),
  };

  return options;
};

export const getReportsData = async (payload) => {
  const options = prepareRequestOptions('/reports/export', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const getRealtimeDashboard = async (payload) => {
  const options = prepareRequestOptions(
    '/reports/realtime-dashboard',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getChannels = async () => {
  const options = prepareRequestOptions('/reports/list/channels', 'GET');

  const response = await axios(options);
  return response.data;
};

export const getVideos = async () => {
  const options = prepareRequestOptions('/reports/list/videos', 'GET');

  const response = await axios(options);
  return response.data;
};

export const getDataList = async (type) => {
  const options = prepareRequestOptions(`/reports/data/${type}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getCounters = async () => {
  const options = prepareRequestOptions('/reports/counters', 'GET');

  const response = await axios(options);
  return response.data;
};

export const getSessionDetails = async (id) => {
  const options = prepareRequestOptions(`/reports/session/${id}/view`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getAppSessionDetails = async (id) => {
  const options = prepareRequestOptions(
    `/reports/app-session/${id}/view`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};
