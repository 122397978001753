import React from 'react';
import { Grid } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import WatchedSecondsChart from '../../components/reports/watched-seconds-chart.component';
import WatchedSessionsChart from '../../components/reports/watched-sessions-chart.component';
import DownloadReports from '../../components/reports/download-reports.component';

const ReportsOverviewView = () => {
  const pageTitle = 'Reports';

  return (
    <AppLayout pageTitle={pageTitle}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <WatchedSecondsChart />
        </Grid>

        <Grid item xs={6}>
          <WatchedSessionsChart />
        </Grid>

        <Grid item xs={6}>
          <DownloadReports />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default ReportsOverviewView;
