import React from 'react';
import AppLayout from '../../layout/app.layout';
import Logout from '../../components/auth/logout.component';

const LogoutView = () => {
  const pageTitle = 'Logout';

  return (
    <AppLayout pageTitle={pageTitle}>
      <Logout />
    </AppLayout>
  );
};

export default LogoutView;
