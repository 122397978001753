import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip, Tooltip } from '@mui/material';

const SubscriptionSku = ({ value }) => {
  const displaySubscription = () => {
    if (value) {
      if (value.includes('monthly')) {
        return (
          <Tooltip title={value}>
            <Chip
              label="Monthly"
              color="info"
              variant="filled"
              size="small"
              sx={{ fontWeight: 700 }}
            />
          </Tooltip>
        );
      }
      if (value.includes('yearly')) {
        return (
          <Tooltip title={value}>
            <Chip
              label="Yearly"
              color="info"
              variant="filled"
              size="small"
              sx={{ fontWeight: 700 }}
            />
          </Tooltip>
        );
      }
      if (value.includes('admin')) {
        return (
          <Tooltip title={value}>
            <Chip
              label="Complimentary"
              color="success"
              variant="filled"
              size="small"
              sx={{ fontWeight: 700 }}
            />
          </Tooltip>
        );
      }

      return (
        <Chip
          label={value}
          color="warning"
          variant="filled"
          size="small"
          sx={{ fontWeight: 700 }}
        />
      );
    }

    return (
      <Typography variant="body2" component="span">
        None
      </Typography>
    );
  };

  return displaySubscription();
};

SubscriptionSku.propTypes = {
  value: PropTypes.any,
};
SubscriptionSku.defaultProps = {
  value: null,
};

export default SubscriptionSku;
