import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Subscriptions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '10%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'username',
    label: 'Username',
    width: '10%',
    align: 'left',
    dataKey: 'username',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '12%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channel',
    label: 'Channel',
    width: '10%',
    align: 'left',
    dataKey: 'channel',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    width: '7%',
    align: 'center',
    dataKey: 'type',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sku',
    label: 'Product',
    width: '10%',
    align: 'left',
    dataKey: 'sku',
    sortable: true,
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '10%',
    align: 'center',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'payableAmount',
    label: 'Amount',
    width: '8%',
    align: 'right',
    dataKey: 'payableAmount',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'status',
    label: 'Status',
    width: '8%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'startDate',
    label: 'Date',
    width: '10%',
    align: 'left',
    dataKey: 'startDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Subscriber',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'username',
        type: CELL_DATA_TYPES.TEXT,
      },
      {
        dataKey: 'email',
        type: CELL_DATA_TYPES.EMAIL,
      },
    ],
  },
  {
    id: 'channel',
    label: 'Channel',
    dataKey: 'channel',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    dataKey: 'type',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sku',
    label: 'Product',
    dataKey: 'sku',
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
  {
    id: 'videoTitle',
    label: 'Video',
    dataKey: 'videoTitle',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'contentId',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'totalAmount',
    label: 'Total Amount',
    dataKey: 'totalAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'discount',
    label: 'Discount',
    dataKey: 'discount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'payableAmount',
    label: 'Payable Amount',
    dataKey: 'payableAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'couponCode',
    label: 'Coupon Code',
    dataKey: 'couponCode',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'manuallyAdded',
    label: 'Manually Created',
    dataKey: 'manuallyAdded',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'startDate',
    label: 'Subscription Start Date',
    dataKey: 'startDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'endDate',
    label: 'Subscription End Date',
    dataKey: 'endDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'trialStartDate',
    label: 'Trial Start Date',
    dataKey: 'trialStartDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'trialEndDate',
    label: 'Trial End Date',
    dataKey: 'trialEndDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'currentPeriodStartDate',
    label: 'Current Period Start Date',
    dataKey: 'currentPeriodStartDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'currentPeriodEndDate',
    label: 'Current Period End Date',
    dataKey: 'currentPeriodEndDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'subscribedFromDevice',
    label: 'Subscribed From',
    dataKey: 'subscribedFromDevice',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriptionId',
    label: 'Subscription ID',
    dataKey: 'subscriptionId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'receiptId',
    label: 'Receipt ID',
    dataKey: 'receiptId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'requestPayload',
    label: 'Payload For Checking',
    dataKey: 'requestPayload',
    type: CELL_DATA_TYPES.CODE,
  },
  {
    id: 'paymentInfo',
    label: 'Other Details',
    dataKey: 'paymentInfo',
    type: CELL_DATA_TYPES.CODE,
  },
];
