import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { VALID_ADMIN_ROLES } from '../../config/const.config';
import { getAdminRoleLabel } from '../../config/admin-roles.config';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Channels from '../form-elements/channels.component';
import Status from '../form-elements/status.component';
import AdminRoles from '../form-elements/admin-roles.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/admin-users.service';
import StyledDialogActions from '../../theme/dialogActionStyles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditUser = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      username: '',
      password: '',
      status: 1,
      role: null,
      channels: [],
    },
  });
  const roleFieldValue = watch('role');

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New user created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('User details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      name: data.name,
      email: data.email,
      username: data.username,
      role: data.role.id,
      status: Number(data.status),
      channels: [],
    };
    (data.channels || []).forEach((c) => {
      payload.channels.push(Number(c.id));
    });
    if (dataId) {
      updateExistingData(payload);
    } else {
      payload.password = data.password;

      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        setValue('name', res.data?.name || '');
        setValue('email', res.data?.email || '');
        setValue('username', res.data?.username || '');
        setValue('status', Number(res.data?.status || 0));
        setValue('role', {
          id: res.data?.role || '',
          title: getAdminRoleLabel(res.data?.role || ''),
        });

        const channelsAccess = [];
        (res.data?.channelsList || []).forEach((c) => {
          channelsAccess.push({
            id: c.id || '',
            code: c.code || '',
            title: c.title || '',
          });
        });
        setValue('channels', channelsAccess);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Full Name *"
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="username"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Username *"
                      type="text"
                      error={!!errors.username}
                      helperText={errors?.username?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={validationRules.REQUIRED_EMAIL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email *"
                      type="text"
                      error={!!errors.email}
                      helperText={errors?.email?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {dataId === 0 && (
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      ...validationRules.REQUIRED,
                      ...validationRules.PASSWORD,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Password *"
                        type={showPassword ? 'text' : 'password'}
                        error={!!errors.password}
                        helperText={errors?.password?.message || null}
                        fullWidth
                        variant="filled"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                title={
                                  !showPassword
                                    ? 'Show password'
                                    : 'Hide password'
                                }
                              >
                                <Iconify
                                  icon={
                                    !showPassword
                                      ? 'eva:eye-fill'
                                      : 'eva:eye-off-fill'
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="role"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <AdminRoles
                      id="user_role"
                      name="user_role"
                      label="Select Role *"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1, 2, 5]}
                      sx={{ width: '100%' }}
                      error={errors?.role?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="channels"
                  control={control}
                  rules={{
                    validate: (value) =>
                      value.length === 0 &&
                      roleFieldValue?.id &&
                      roleFieldValue.id === VALID_ADMIN_ROLES.CONTENT_OWNER
                        ? 'This field is required.'
                        : true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Channels
                      id="user_channels"
                      name="user_channels"
                      label={`Select Channels${
                        roleFieldValue?.id === VALID_ADMIN_ROLES.CONTENT_OWNER
                          ? ' *'
                          : ''
                      }`}
                      defaultValue={value}
                      multiple
                      disabled={
                        !(
                          roleFieldValue?.id === VALID_ADMIN_ROLES.CONTENT_OWNER
                        )
                      }
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.channels?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="user_status"
                      name="user_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={dataId ? [0, 1, 2] : [0, 1]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                &nbsp;
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditUser.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditUser;
