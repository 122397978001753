import CELL_DATA_TYPES from '../cell-types.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'videoId',
    label: 'ID',
    width: '20%',
    align: 'left',
    dataKey: 'videoId',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'title',
    label: 'Title',
    width: '40%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    width: '10%',
    align: 'right',
    dataKey: 'duration',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'totalSessions',
    label: 'Total Watch Sessions',
    width: '15%',
    align: 'right',
    dataKey: 'totalSessions',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalSecondsWatch',
    label: 'Total Watch Time',
    width: '15%',
    align: 'right',
    dataKey: 'totalSecondsWatch',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
];

export const LISTING_ACTIONS = [];
