import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListChannels from '../../components/content/list-channels.component';

const ContentChannelsView = () => {
  const pageTitle = 'Engagement / Views by App';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListChannels />
    </AppLayout>
  );
};

export default ContentChannelsView;
