import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListUsers from '../../components/admin-users/list-users.component';

const AdminUsersView = () => {
  const pageTitle = 'Settings / Admin Access';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListUsers />
    </AppLayout>
  );
};

export default AdminUsersView;
