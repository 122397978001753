import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListLiveUsers from '../../components/reports/list-live-users.component';

const ReportsLiveUsersView = () => {
  const pageTitle = 'Realtime Users';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListLiveUsers />
    </AppLayout>
  );
};

export default ReportsLiveUsersView;
