import React, { useEffect } from 'react';
import ROUTE_PATH from '../../config/routes.config';
import { setAdminUserLoggedOut } from '../../utils/local-cache.util';
import Loader from '../common/loader.component';
import { adminUserLogout } from '../../services/auth.service';

const Logout = () => {
  useEffect(() => {
    adminUserLogout()
      .then(() => {
        setAdminUserLoggedOut();

        window.location.href = ROUTE_PATH.LOGIN;
      })
      .catch(() => {
        setAdminUserLoggedOut();

        window.location.href = ROUTE_PATH.LOGIN;
      });
  }, []);

  return <Loader />;
};

export default Logout;
