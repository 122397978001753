export default function FormHelperText() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '4px',
        },
      },
    },
  };
}
