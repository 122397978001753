import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSubscribers from '../../components/subscribers/list-subscribers.component';

const SubscribersView = () => {
  const pageTitle = 'User Management / All Users';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSubscribers />
    </AppLayout>
  );
};

export default SubscribersView;
