import CELL_DATA_TYPES from '../cell-types.config';

// Channels module configurations
export const LISTING_COLUMNS = [
  {
    id: 'channel',
    label: 'Channel',
    width: '25%',
    align: 'left',
    dataKey: 'channel',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'apiKey',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'totalAppSessions',
    label: 'Total App Sessions',
    width: '15%',
    align: 'right',
    dataKey: 'totalAppSessions',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalSessions',
    label: 'Total Watch Sessions',
    width: '15%',
    align: 'right',
    dataKey: 'totalSessions',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalSecondsWatch',
    label: 'Total Watch Time',
    width: '15%',
    align: 'right',
    dataKey: 'totalSecondsWatch',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'averageWatchPerSession',
    label: 'Avg Watched Per Video Session',
    width: '15%',
    align: 'right',
    dataKey: 'averageWatchPerSession',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'percentageVideoCompletion',
    label: 'Video Completion (%)',
    width: '15%',
    align: 'right',
    dataKey: 'percentageVideoCompletion',
    sortable: true,
    type: CELL_DATA_TYPES.PERCENTAGE,
  },
];

export const LISTING_ACTIONS = [];
