import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, VALID_ADMIN_ROLES } from '../const.config';

// Admin users module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '15%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'username',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'email',
    label: 'Email',
    width: '20%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'role',
    label: 'Role',
    width: '10%',
    align: 'left',
    dataKey: 'role',
    sortable: true,
    type: CELL_DATA_TYPES.ADMIN_ROLE,
  },
  {
    id: 'channelsAccess',
    label: 'Channels Access',
    width: '25%',
    align: 'left',
    dataKey: 'channelsAccess',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
    conditional: (val) => val.role !== VALID_ADMIN_ROLES.SUPER_ADMIN,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.CHANGE_PASSWORD.value,
    label: ACTIONS.CHANGE_PASSWORD.label,
    conditional: (val) => val.role !== VALID_ADMIN_ROLES.SUPER_ADMIN,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
    conditional: (val) => val.role !== VALID_ADMIN_ROLES.SUPER_ADMIN,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    dataKey: 'email',
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'username',
    label: 'Username',
    dataKey: 'username',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'role',
    label: 'Role',
    dataKey: 'role',
    type: CELL_DATA_TYPES.ADMIN_ROLE,
  },
  {
    id: 'channelsAccess',
    label: 'Channels',
    dataKey: 'channelsAccess',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
