import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stack,
  Button,
  Divider,
  Typography,
  TextField,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Scrollbar from '../common/scrollbar.component';
import Channels from '../form-elements/channels.component';
import Videos from '../form-elements/videos.component';
import Platforms from '../form-elements/platforms.component';
import WatchTypes from '../form-elements/watch-types.component';
import Datepicker from '../form-elements/datepicker.component';
import Subscribers from '../form-elements/subscribers.component';

const FilterSessions = ({ values, onCancel, onSuccess, onClear }) => {
  const [filters, setFilters] = useState(values);

  const handleApplyFilters = () => {
    setTimeout(() => {
      onSuccess(filters);
    }, 250);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Filters
        </Typography>
        <IconButton onClick={onCancel}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>

      <Divider />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <form id="list-data-filters" onSubmit={(e) => e.preventDefault()}>
          <Stack sx={{ p: 2 }}>
            <Stack spacing={1} sx={{ marginBottom: 1 }}>
              <Datepicker
                id="fl_start_date"
                name="fl_start_date"
                label="Sessions From Date"
                defaultValue={filters.startDate}
                clearable
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    startDate: v,
                  });
                }}
                sx={{ width: '100%' }}
              />

              <Datepicker
                id="fl_end_date"
                name="fl_end_date"
                label="Sessions To Date"
                defaultValue={filters.endDate}
                clearable
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    endDate: v,
                  });
                }}
                sx={{ width: '100%' }}
              />
            </Stack>

            <Channels
              id="fl_channels"
              name="fl_channels"
              label="Channels"
              defaultValue={filters.channels}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  channels: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <Subscribers
              id="fl_subscribers"
              name="fl_subscribers"
              label="Subscribers"
              defaultValue={filters.subscribers}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  subscribers: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <Videos
              id="fl_videos"
              name="fl_videos"
              label="Videos"
              defaultValue={filters.videos}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  videos: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <Platforms
              id="fl_platforms"
              name="fl_platforms"
              label="Platforms"
              defaultValue={filters.platforms}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  platforms: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <WatchTypes
              id="fl_watch_types"
              name="fl_watch_types"
              label="Session Types"
              defaultValue={filters.types}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  types: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <TextField
              id="fl_ip"
              name="fl_ip"
              label="IP Address"
              variant="filled"
              defaultValue={filters.ip}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  ip: e.target.value,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <TextField
              id="fl_device_id"
              name="fl_device_id"
              label="Device ID"
              variant="filled"
              defaultValue={filters.deviceId}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  deviceId: e.target.value,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />
          </Stack>
        </form>
      </Scrollbar>

      <Divider />

      <Stack spacing={1} sx={{ p: 2 }}>
        <Button
          fullWidth
          form="list-data-filters"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          fullWidth
          type="button"
          color="secondary"
          variant="contained"
          startIcon={<Iconify icon="ic:round-clear-all" />}
          onClick={onClear}
        >
          Clear All
        </Button>
      </Stack>
    </>
  );
};

FilterSessions.propTypes = {
  values: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default FilterSessions;
