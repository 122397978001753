import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import Status from '../form-elements/status.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/smtp-settings.service';
import StyledDialogActions from '../../theme/dialogActionStyles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditSMTPSetting = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      host: '',
      port: 25,
      encryption: 'None',
      username: '',
      password: '',
      senderEmail: '',
      senderName: '',
      status: 1,
      notes: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New email setting created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('Email setting updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        setValue('name', res.data?.name || '');
        setValue('host', res.data?.host || '');
        setValue('port', Number(res.data?.port || 25));
        setValue('encryption', res.data?.encryption || 'None');
        setValue('username', res.data?.username || '');
        setValue('password', res.data?.password || '');
        setValue('senderEmail', res.data?.senderEmail || '');
        setValue('senderName', res.data?.senderName || '');
        setValue('status', res.data?.status || 0);
        setValue('notes', res.data?.notes || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name *"
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="host"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Host *"
                      type="text"
                      error={!!errors.host}
                      helperText={errors?.host?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="port"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Port *"
                      type="number"
                      error={!!errors.port}
                      helperText={errors?.port?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="encryption"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      error={!!errors?.encryption?.message}
                      variant="filled"
                    >
                      <InputLabel id="field-status-select-label">
                        Encryption *
                      </InputLabel>
                      <Select
                        id="smtp_status"
                        name="smtp_status"
                        label="Encryption *"
                        value={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                      >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="SSL">SSL</MenuItem>
                        <MenuItem value="TLS">TLS</MenuItem>
                      </Select>
                      {errors?.encryption?.message && (
                        <FormHelperText>
                          {errors.encryption.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="username"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Username *"
                      type="text"
                      error={!!errors.username}
                      helperText={errors?.username?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="password"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password *"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors?.password?.message || null}
                      fullWidth
                      variant="filled"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                              title={
                                !showPassword
                                  ? 'Show password'
                                  : 'Hide password'
                              }
                            >
                              <Iconify
                                icon={
                                  !showPassword
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="senderEmail"
                  control={control}
                  rules={validationRules.REQUIRED_EMAIL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Sender Email *"
                      type="text"
                      error={!!errors.senderEmail}
                      helperText={errors?.senderEmail?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="senderName"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Sender Name *"
                      type="text"
                      error={!!errors.senderName}
                      helperText={errors?.senderName?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="subscriber_status"
                      name="subscriber_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1, 2]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} />

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="notes"
                  control={control}
                  rules={{ ...maxLengthValidation(200) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes"
                      type="text"
                      error={!!errors.notes}
                      helperText={errors?.notes?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditSMTPSetting.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditSMTPSetting;
