import { VALID_ADMIN_ROLES } from './const.config';

// Define admin roles list
const ADMIN_ROLES_LIST = [
  {
    id: VALID_ADMIN_ROLES.SUPER_ADMIN,
    title: 'Super Admin',
  },
  {
    id: VALID_ADMIN_ROLES.ADMIN,
    title: 'Admin',
  },
  {
    id: VALID_ADMIN_ROLES.CONTENT_OWNER,
    title: 'Channel Owner',
  },
];

export const getAdminRoleLabel = (val) => {
  let label = '';

  ADMIN_ROLES_LIST.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export default ADMIN_ROLES_LIST;
