import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Watch sessions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'channel',
    label: 'Channel',
    width: '15%',
    align: 'left',
    dataKey: 'channel',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'subscriberFullName',
        type: CELL_DATA_TYPES.TEXT,
      },
      {
        dataKey: 'subscriberUsername',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '10%',
    align: 'center',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'video',
    label: 'Video',
    width: '20%',
    align: 'left',
    dataKey: 'video',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'videoCmsId',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'ip',
    label: 'Location / Device UID',
    width: '15%',
    align: 'left',
    dataKey: 'ip',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'deviceId',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'monetizationType',
    label: 'Type',
    width: '10%',
    align: 'center',
    dataKey: 'monetizationType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sessionStartedAt',
    label: 'Session Start Time',
    width: '15%',
    align: 'left',
    dataKey: 'sessionStartedAt',
    sortable: true,
    type: CELL_DATA_TYPES.DATETIME,
  },
  {
    id: 'totalSecondsWatch',
    label: 'Total Time Watched',
    width: '10%',
    align: 'right',
    dataKey: 'totalSecondsWatch',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'sessionId',
    label: 'Session ID',
    dataKey: 'sessionId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channel',
    label: 'Channel',
    dataKey: 'channel',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriberFullName',
    label: 'User / Subscriber',
    dataKey: 'subscriberFullName',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'subscriberUsername',
        type: CELL_DATA_TYPES.TEXT,
      },
      {
        dataKey: 'subscriberEmail',
        type: CELL_DATA_TYPES.EMAIL,
      },
    ],
  },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'videoTitle',
    label: 'Video',
    dataKey: 'videoTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'playlistTitle',
    label: 'Playlist',
    dataKey: 'playlistTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'deviceUID',
    label: 'Device UID',
    dataKey: 'deviceUID',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'Location',
    dataKey: 'ip',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'monetizationType',
    label: 'Type',
    dataKey: 'monetizationType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sessionStartedAt',
    label: 'Session Start Time',
    dataKey: 'sessionStartedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },

  {
    id: 'sessionEndedAt',
    label: 'Session End Time',
    dataKey: 'sessionEndedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'videoPlayedFrom',
    label: 'Video Played From',
    dataKey: 'videoPlayedFrom',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'videoWatchProgress',
    label: 'Video Watched Til',
    dataKey: 'videoWatchProgress',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'totalSecondsWatch',
    label: 'Total Watched',
    dataKey: 'totalSecondsWatch',
    type: CELL_DATA_TYPES.DURATION,
  },
];
