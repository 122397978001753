import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CountWidget from './count-widget.component';
import { getCounters } from '../../services/reports.service';
import { getLocalValue } from '../../utils/local-cache.util';
import {
  LS_ADMIN_USER_ROLE,
  VALID_ADMIN_ROLES,
} from '../../config/const.config';

const Counters = () => {
  const userRole = getLocalValue(LS_ADMIN_USER_ROLE);
  const [counters, setCounters] = useState({
    channels: 0,
    videos: 0,
    sessions: 0,
    subscribers: 0,
  });

  useEffect(() => {
    getCounters()
      .then((res) => {
        setCounters({
          ...counters,
          channels: res?.data?.channels || 0,
          videos: res?.data?.videos || 0,
          sessions: res?.data?.sessions || 0,
          subscribers: res?.data?.subscribers || 0,
        });
      })
      .catch(() => {
        // ignore
      });
  }, []);

  return (
    <Grid container spacing={3} mb={3}>
      <Grid
        item
        xs={12}
        sm={userRole === VALID_ADMIN_ROLES.CONTENT_OWNER ? 4 : 3}
        md={userRole === VALID_ADMIN_ROLES.CONTENT_OWNER ? 4 : 3}
      >
        <CountWidget
          title="Channels / Apps"
          total={counters.channels}
          icon="ic:twotone-checklist"
          color="error"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={userRole === VALID_ADMIN_ROLES.CONTENT_OWNER ? 4 : 3}
        md={userRole === VALID_ADMIN_ROLES.CONTENT_OWNER ? 4 : 3}
      >
        <CountWidget
          title="Users"
          total={counters.subscribers}
          icon="ci:users"
          color="warning"
        />
      </Grid>
      {userRole !== VALID_ADMIN_ROLES.CONTENT_OWNER && (
        <Grid item xs={12} sm={3} md={3}>
          <CountWidget
            title="Videos"
            total={counters.videos}
            color="info"
            icon="octicon:video-16"
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={userRole === VALID_ADMIN_ROLES.CONTENT_OWNER ? 4 : 3}
        md={userRole === VALID_ADMIN_ROLES.CONTENT_OWNER ? 4 : 3}
      >
        <CountWidget
          title="Video Watch Sessions"
          total={counters.sessions}
          color="success"
          icon="ic:outline-list-alt"
        />
      </Grid>
    </Grid>
  );
};

export default Counters;
