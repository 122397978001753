import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@mui/material';
import Iconify from '../common/iconify.component';

const PasswordLabel = ({ value }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Typography variant="body2" component="span">
        {!showPassword ? '**********' : value}
      </Typography>
      &nbsp;&nbsp;&nbsp;
      <IconButton
        size="small"
        onClick={() => setShowPassword(!showPassword)}
        title={!showPassword ? 'Show password' : 'Hide password'}
      >
        <Iconify icon={!showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
      </IconButton>
    </>
  );
};

PasswordLabel.propTypes = {
  value: PropTypes.string.isRequired,
};

export default PasswordLabel;
