import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListRealtimeLiveUsers from '../../components/reports/list-realtime-live-users.component';

const RealtimeDashboardView = () => {
  const pageTitle = 'Realtime Apps';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListRealtimeLiveUsers />
    </AppLayout>
  );
};

export default RealtimeDashboardView;
